 @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


*:where(:not(iframe, canvas, img, svg, video):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  font-family: 'Montserrat', sans-serif;
}

a {
  cursor: revert;
}

ol,
ul,
menu {
  list-style: none;
}

img {
  max-width: 100%;
}

table {
  border-collapse: collapse;
}

textarea {
  white-space: revert;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  line-break: after-white-space;
}

:where([draggable='true']) {
  -webkit-user-drag: element;
}

p {
  font-size: 14px;
  color: #5c6269;
  white-space: pre-wrap;
}
