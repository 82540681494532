.vh-100 {
  height: 100vh;
}
.vh-100c {
  height: calc(100vh - 50px);
}
.w-100 {
  width: 100%;
}
.wrapper {
  height: calc(100vh - 50px);
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.leaflet-container a.leaflet-popup-close-button {
  width: 25px !important;
  height: 25px !important;
}
.leaflet-popup-content-wrapper {
  border-radius: 0.3rem !important;
}
.leaflet-popup-content {
  width: 280px !important;
}
.butto-day-week {
  &:hover {
    background-color: currentColor!important;
  }
}
.popup-info {
  // background-color: red;
  width: 300px;
  ul {
    li {
      font-size: 15px;
      strong {
        font-weight: 600;
      }
    }
  }
}

.popup-info-sweep {
  width: 330px;
  ul {
    li {
      font-size: 15px;
      strong {
        font-weight: 600;
      }
    }
  }
}
.popup-info-competing-sector {
  display: flex;
  justify-content: space-around;
  width: 250px;
  ul {
    li {
      font-size: 15px;
      strong {
        font-weight: 600;
      }
    }
  }
}
.popup-actions {
  display: flex;
  flex-direction: column;
  width: 250px;
  ul {
    li {
      font-size: 15px;
      strong {
        font-weight: 600;
      }
    }
  }
}

.btnAcoes  {
  width: 60px;
  height: 29px;
  align-items: center;
  align-self: center;
  text-align: center;
  /* Adicione aqui os estilos específicos do botão que deseja manter */
  /* Por exemplo: */
  background-color: #0288d1;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btnDeixarConcorrente {
  align-items: center;
  align-self: center;
  text-align: center;
  /* Adicione aqui os estilos específicos do botão que deseja manter */
  /* Por exemplo: */
  background-color: #0288d1;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 15px;
  margin: 5px;
}

.btnReturn {
  align-items: center;
  align-self: center;
  text-align: center;
  /* Adicione aqui os estilos específicos do botão que deseja manter */
  /* Por exemplo: */
  background-color: #0288d1;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 3px 16px;
}